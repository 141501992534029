import React, { useState } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa'

const Immerisive = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const handlePauseClick = () => {
    setIsPlaying(false);
  };

  return (
    <div className="flex flex-col w-full space-y-2  aspect-video  py-2 mt-8 shadow-lg bg-[#82828233] bg-opacity-50 backdrop-blur-2xl rounded-3xl transition-colors ">
      <div className="flex justify-center items-center rounded-2xl h-full mt-2  bg-[#EDEDED] w-11/12 mx-auto">
        <img
          src="/BEEBOX WEBSITE v2.gif"
          alt="Right Screen"
          className="w-1/2 aspect-video mx-4 rounded-3xl"
        />
      </div>
      {isPlaying && (
        <div className="flex items-center justify-center w-full absolute   left-1/2 transform -translate-x-1/2  xl:absolute  top-[10px] xl:left-1/2 xl:transform xl:-translate-x-1/2">
          <img
            src="/AR watch.gif"
            alt="AR Watch"
            className="xl:w-1/2 xl:rounded-3xl w-2/3 rounded-3xl lg:w-[80%] lg:rounded-3xl"
          />
        </div>
      )}
      <div className="flex justify-start  md:flex md:justify-start mx-3 md:ml-8 w-full  ">
        {!isPlaying ? (
          <div
            className="flex items-center text-lg  gap-4 font-lexend justify-center rounded-2xl border border-white md:px-5 md:py-2  px-2 py-1 hover:bg-white  hover:text-black  text-white cursor-pointer"
            onClick={handlePlayClick}
          >
            PLAY
            <FaPlay className=" text-sm" />
          </div>
        ) : (
          <div
            className="flex items-center gap-2 text-lg font-lexend justify-center rounded-2xl border border-white  hover:bg-white md:px-5 md:py-2  px-2 py-1 hover:text-black text-white cursor-pointer"
            onClick={handlePauseClick}
          >
             PAUSE
             <i className='flex justify-center items-center'>

            <FaPause className=" text-md" />
             </i>
          </div>
        )}
      </div>
      <p className="text-xs md:text-sm xl:text-md text-white xl:mt-2 md:ml-8 w-11/12 mx-3  2xl:text-md  2xl:mt-2 font-montserrat   ">
        Dive into your exclusive content and hallmarks of production.
      </p>
    </div>
  )};

export default Immerisive;
