import React from "react";

const Rightscreen = () => {
  return (
    <div className="relative flex flex-col items-center mt-14">
      <div className="flex justify-center w-full md:w-[90%] py-2 px-2 overflow-hidden shadow-lg bg-[#82828233] bg-opacity-50 backdrop-blur-2xl rounded-3xl transition-colors ">
        <img
          src="/Right Screen.png"
          alt="Right Screen"
          className="w-full md:w-[87%] mx-auto mb-"
        />
      </div>


<div className="flex justify-end absolute  top-28 left-[88%] 2xl:absolute 2xl:top-1/2 2xl:left-[85%]">

      <div className=" flex flex-col justify-center items-center shadow-lg bg-[#A9A9A94D]  rounded-3xl h-[3rem] md:h-[8rem] md:w- 2xl:h-[7rem] 2xl:w-32  ">
        <p className="text-center text-[9px] text-white font-medium  md:text-center md:text-xl md:text-white md:font-semibold">
          Get in Touch
        </p>
        <a
          href="#Contact"
          className=" bg-orange-500 mt-1 md:mt-3 text-white text-[5px] rounded-sm px-0  md:text-[12px] md:py-1 md:rounded md:px-5"
          >
          CONTACT
        </a>
      </div>
          </div>

      <div
        className="flex justify-end w-full mt-9 absolute transform -translate-x-1/2 top-[64%] left-[32%] 
                    md:top-[68%] md:left-[30%] xl:top-[75%] xl:left-[14.3rem] "
      >
        <a
          href="#About"
          className="text-black font-normal font-lexend text-sm md:text-md lg:text-md xl:flex xl:justify-end xl:text-lg cursor-pointer  
                    rounded-2xl border border-black/50 md:px-5 md:py-2  px-1 py-1 
                    hover:bg-black hover:text-white md:hover:bg-black xl:hover:text-white xl:hover:bg-black md:hover:text-whitetransition duration-300"
        >
          Find More
        </a>
      </div>
    </div>
  );
};

export default Rightscreen;
