import React from "react";
import Marquee from "react-fast-marquee";
import img1 from "../assets/Images/Afcons.png";
import img2 from "../assets/Images/CAT.png";
import img3 from "../assets/Images/INS.png";
import img4 from "../assets/Images/Navy.png";
import img5 from "../assets/Images/R2D2.png";
import img6 from "../assets/Images/TATA.png";
import img7 from "../assets/Images/Wendt.png";
import img8 from "../assets/Images/asia.png";
import img9 from "../assets/Images/enoah.png";
import img10 from "../assets/Images/nav.png";
import img11 from "../assets/Images/sem.png";
import img12 from "../assets/Images/shib.png";
import img13 from "../assets/Images/te.png";
import img14 from "../assets/Images/vio.png";

const Clients = () => {
  return (
    <div className="flex flex-col md:my-16 mx-6 md:mx-32 scroll-smooth">
      <div className="relative overflow-hidden">
        <Marquee loop={0} speed={30} pauseOnHover={true}>
          <div className="flex flex-row justify-center items-center space-x-8 md:space-x-20 gap-10">
            <img src={img1} alt="Afcons" className="h-8 md:h-10 ml-20" />
            <img src={img2} alt="CAT" className="h-8 md:h-10" />
            <img src={img3} alt="INS" className="h-8 md:h-10" />
            <img src={img4} alt="Navy" className="h-8 md:h-10" />
            <img src={img5} alt="R2D2" className="h-8 md:h-10" />
            <img src={img6} alt="TATA" className="h-8 md:h-12" />
            <img src={img7} alt="Wendt" className="h-8 md:h-12" />
          </div>
        </Marquee>
        <Marquee loop={0} speed={30} direction="right" pauseOnHover={true}>
          <div className="flex flex-row justify-center items-center md:space-y-20 space-x-8 md:space-x-20 mt-10 gap-10">
            <img src={img8} alt="Asia" className="h-8 md:h-12 ml-16 md:mt-20" />
            <img src={img9} alt="Enoah" className="h-8 md:h-12" />
            <img src={img10} alt="Nav" className="h-8 md:h-12" />
            <img src={img11} alt="SEM" className="h-8 md:h-12" />
            <img src={img12} alt="Shib" className="h-8 md:h-12" />
            <img src={img13} alt="TE" className="h-8 md:h-12" />
            <img src={img14} alt="Vio" className="h-8 md:h-12" />
          </div>
        </Marquee>
        <div className="absolute top-0 bottom-0 left-0 w-8 md:w-10 bg-gradient-to-r from-white pointer-events-none"></div>
        <div className="absolute top-0 bottom-0 right-0 w-8 md:w-10 bg-gradient-to-l from-white pointer-events-none"></div>
      </div>
    </div>
  );
};

export default Clients;
