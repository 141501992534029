import React from 'react';

const ArrowComponent = () => {
  return (
    <div className="bg-[#2e2929] h-screen flex justify-center items-center relative">
      {/* Left Arrow */}
      <div className="arrow cursor-pointer absolute top-1/2 left-[55px] md:left-[75px]  transform -translate-x-1/2 -translate-y-1/2 rotate-90">
        <span className="block w-[4vw] h-[4vw]  md:w-[1.5vw] md:h-[1.5vw] border-b-[5px] border-r-[5px] border-white transform rotate-45 -m-2.5 animate-animate"></span>
        <span className="block w-[4vw] h-[4vw]  md:w-[1.5vw] md:h-[1.5vw] border-b-[5px] border-r-[5px] border-white transform rotate-45 -m-2.5 animate-animate delay-[0.2s]"></span>
        <span className="block w-[4vw] h-[4vw]  md:w-[1.5vw] md:h-[1.5vw] border-b-[5px] border-r-[5px] border-white transform rotate-45 -m-2.5 animate-animate delay-[0.4s]"></span>
      </div>

      {/* Right Arrow */}
      <div className="arrow cursor-pointer absolute top-1/2 right-[21rem] 2xl:right-[32rem] md:right-[32rem] transform -translate-x-1/2 -translate-y-1/2 -rotate-90">
        <span className="block w-[4vw] h-[4vw] sm:w-[2vw] sm:h-[2vw] md:w-[1.5vw] md:h-[1.5vw] border-b-[5px] border-r-[5px] border-white transform rotate-45 -m-2.5 animate-animate"></span>
        <span className="block w-[4vw] h-[4vw] sm:w-[4vw] sm:h-[2vw] md:w-[1.5vw] md:h-[1.5vw] border-b-[5px] border-r-[5px] border-white transform rotate-45 -m-2.5 animate-animate delay-[0.2s]"></span>
        <span className="block w-[4vw] h-[4vw] sm:w-[4vw] sm:h-[2vw] md:w-[1.5vw] md:h-[1.5vw] border-b-[5px] border-r-[5px] border-white transform rotate-45 -m-2.5 animate-animate delay-[0.4s]"></span>
      </div>
    </div>
  );
};

export default ArrowComponent;
