import React from 'react';

const ServiceBar = ({ currentSlide, handleMenuClick }) => {
  const menuItems = [
    { id: 0, label: 'Sales & Marketing', href: '#home' },
    { id: 1, label: 'Industrial Training', href: '#about' },
    { id: 2, label: 'Operations & Maintenance', href: '#services' },
    { id: 3, label: 'After Sales Support', href: '#portfolio' },
  ];

  return (
    <nav className=" p-4 ">
      <div className="w-full lg:w-[99%]  mx-auto flex flex-wrap justify-center items-centerv">
        <div className="text-sm flex flex-wrap gap-6 lg:gap-16 justify-center uppercase w-full lg:w-auto sm:mx-6 px-2  border border-white/10 bg-white/10 py-1 shadow-md rounded-3xl transition-colors backdrop-blur-sm">
          {menuItems.map((item) => (
            <a
              key={item.id}
              href={item.href}
              onClick={() => handleMenuClick(item.id)}
              className={`block mt-2 lg:mt-0 text-white items-center text-lg lg:text-xl py-2 font-normal px-12 font-lexend rounded-3xl mr-2 lg:mr-4 ${
                currentSlide === item.id ? 'bg-orange-500 text-white' : 'hover-border'
              }`}
            >
              {item.label}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default ServiceBar;
