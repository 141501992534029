import React from 'react'

const Whatsapp = () => {
  return (
    <div className="flex flex-col items-center p-8  w-1/4">
    <div className="bg-white p-4 shadow-xl rounded-lg text-center flex- flex-col justify-center items-center">
      <p className="text-lg font-semibold mb-2">Reach out on WhatsApp</p>
      <div className='flex justify-center items-center'>
      <img
                  src="/beeboxscanner1.jpeg"
                  alt="Beebox Scanner"
                  className="w-24 h-24 md:w-44 md:h-44 rounded-xl"
                />
                </div>
      <button className="  mt-4 bg-green-500 text-center text-white font-bold px-28 py-2 rounded flex justify-center items-center">
      <a
                href="https://api.whatsapp.com/send/?phone=918754483451&amp;text=Hello+Beebox,+I+would+like+to+get+more+information&amp;type=phone_number&amp;app_absent=0"
                title="Whatsapp"
                className="flex items-center gap-2 text-white text-sm md:text-base"
              >
        <i className="fab fa-whatsapp text-3xl text-center"></i>
              </a>
      </button>
    </div>
  </div>

  )
}

export default Whatsapp