import React from "react";
import "@fontsource/lexend/400.css";
import { FaYoutube, FaPhone, FaLinkedin, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <section className="text-white font-[Lexend] z-10 scroll-m-36 bg-white">
      <div className="py-8 px-4 mx-auto w-11/12 lg:w-11/12">
        <div className="flex flex-col md:flex-row justify-between items-center gap-6">
          {/* Left Section: Branding */}
          <div className="w-full flex flex-col justify-center items-center  md:flex-row md:justify-end md:w-1/3">
            <div className="flex items-center gap-3 py-3">
              <img
                src="https://www.beebox3d.com/assets/images/logo1.png"
                className="md:w-12 md:h-12 w-10 h-10"
                alt="Logo"
              />
              <div className="text-2xl font-bold text-black">
                Beebox Studios
              </div>
            </div>
            <p className="font-semibold text-black px-1">Check us out at</p>
            <div className="mt-4 flex flex-wrap gap-3">
              <a
                href="tel:+91-875-448-3451"
                className="py-2 px-3 border border-black text-black rounded-full flex items-center"
              >
                <FaPhone />
              </a>
              <a
                href="https://www.linkedin.com/company/beebox-studios/posts/"
                className="py-2 px-3 border border-black text-black rounded-full flex items-center"
              >
                <FaLinkedin />
              </a>
              <a
                href=""
                className="py-2 px-3 border border-black text-black rounded-full flex items-center"
              >
                <FaYoutube />
              </a>
              <a
                href="mailto:info@beeboxstudios.com"
                className="py-2 px-3 border border-black text-black rounded-full flex items-center"
              >
                <FaEnvelope />
              </a>
            </div>
          </div>

          {/* Right Section: Support Section */}
          <div className="flex flex-col justify-center items-center  md:flex-row md:justify-end p-6  rounded-xl w-full md:w-2/3">
            <div className="text-center">
              <p className="text-black font-semibold text-xl md:text-2xl">
                With the support of
              </p>
              <div className="flex flex-wrap justify-center gap-4 mt-4">
                <img
                  src="/IITMIC.png"
                  alt="IITMIC"
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full"
                />
                <img
                  src="/RTBI.png"
                  alt="RTBI"
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full"
                />
                <img
                  src="/IITMRP.png"
                  alt="IITMRP"
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full"
                />
                <img
                  src="/gem-logo.png"
                  alt="GEM"
                  className="w-16 h-16 md:w-24 md:h-24"
                />
                <img
                  src="/nvidia.png"
                  alt="NVIDIA"
                  className="w-16 h-16 md:w-40 md:h-24"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
