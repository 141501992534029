import React, { useState, useEffect } from 'react';

const BlurredCircle = () => {
  const [currentColor, setCurrentColor] = useState(0);
  const colors = ['from-blue-500 to-red-500', 'from-orange-400 to-fuchsia-500','from-yellow-400 to-pink-600']; 
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColor((prevColor) => (prevColor + 1) % colors.length);
    }, 2000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute w-[700px] h-[450px] flex  justify-center">
      <div className="  rounded-full overflow-hidden">
        <div
          className={`absolute inset-0 bg-gradient-to-r ${colors[currentColor]} rounded-full opacity-90 filter blur-3xl  gradient element-to-rotate`}
        ></div>
        <div className="relative  flex items-center justify-center ">
          <h1 className="text-white text-4xl font-bold"></h1>
        </div>
      </div>
    </div>
  );
};

export default BlurredCircle;
