import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import Skid from "../pages/Skid Steer.mp4";

const Interact = () => {


   
  const routeChange = () => { 
    alert('Button clicked');
    window.location.href = `/Operate`;
  }

    return (
        <div id='Interact' className='h-screen md:pt-8 scroll-smooth'>
            <div className="relative">
                <video
                    className="absolute inset-0 w-full h-screen object-cover mt-56"
                    autoPlay
                    loop
                    muted
                >
                    <source src={Skid} type="video/mp4" className='xl:h-screen' />
                </video>
                <div className="absolute"></div>
                <div className='relative z-10 h-full flex flex-col justify-start items-start text-start ml-20 '>
                   


                    <div className="xl:flex xl:ml-24 2xl:ml-10 gap-2 items-center whitespace-nowrap  justify-center flex ml-3 bg-gray-100 font-montserrat  xl:text-sm text-xs font-semibold xl:py-3 xl:px-5 md:py-4 md:px-9 px-5  py-2 rounded-3xl shadow">
              <i className='text-lg text-amber-400'>
                <PiStarFourFill />
              </i>
              OUR CREATION
            </div>
                    <h1 className='flex mx-4 my-2 sm:my-4 sm:ml-10 text-2xl sm:text-4xl font-semibold mt-2 text-white font-montserrat'>
                        Interactive 3D<br />Machinery Tutorial
                    </h1>
                    
                </div>
            </div>
        </div>
    );
};

export default Interact;
