import React from 'react';

const Diamond = () => {
  return (
    <div className="flex justify-center mx-auto w-full   xl:h-[90%]  xl:ml-20  mt-5  rounded-3xl">
      <div className='flex-none '>
      <img 
        src="./Right-Bottom.png" 
        alt="Right-Bottom" 
        className="absolute w-1/2 right-[5%]" 
      />
      <img 
        src="./Left-Hexagons.png" 
        alt="Left-Hexagons" 
        className="absolute w-[55%] left-[5%] top-5" 
      />
      
      </div>

    </div>
  );
}

export default Diamond;
