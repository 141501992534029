import React from 'react';
import { PiStarFourFill } from "react-icons/pi";
import { BiPaperPlane } from "react-icons/bi";
import Send from './send';
const HeroSection = () => {
  return (
    <section id="Contact" className=" w-11/12 mx-auto px-8 py-12 md:py-24 border-b-[2.5px] border-slate-300 ">
        <div className="gap-2 mb-1 flex-none w-40 whitespace-nowrap flex justify-start items-start bg-gray-100 font-montserrat xl:text-sm text-xs font-semibold xl:py-2 xl:px-3 md:py-4 md:px-9 px-5 py-2 rounded-3xl shadow">
          <i className="text-lg text-amber-400">
            <PiStarFourFill />
          </i>
          OUR CONTACT
        </div>
      <div className="flex flex-col md:flex-row items-center justify-between">

    
        {/* Left Side: Company Info */}
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
        <h1 className=' font-lexend font-bold text-black  text-center py-8 text-3xl'>
          Find us at
        </h1>
        <div className="relative">
              <img src="/MacBook Mockup.png" alt="image" className="w-[990px]" />
              <div className="absolute top-[3.7%] left-[9.1%] w-[82%] h-[86.3%] overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6996041325538!2d80.24036047453232!3d12.991055214452494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526787f95d413d%3A0x3af382f9e2662992!2sBeebox%20Studios%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1720426701329!5m2!1sen!2sin"
                  width="420"
                  height="470"
                  loading="lazy"
                  title="Google Maps"
                  className="w-full"
                ></iframe>
              </div>
            </div>
        </div>
        



        {/* Right Side: Details and Buttons */}
        <div className="w-full md:w-1/2 flex justify-center items-center ">
        <div className='md:w-1/2 w-full  '>
          <div className="bg-[#ffb833] flex-col justify-center items-center rounded-3xl p-2 md:p-6 shadow-lg  text-center">
            <h2 className="text-xl md:text-5xl font-semibold text-gray-800 mb-4">
              Or hit us up!
            </h2>
 
 <div className=' flex justify-center items-center'> 
   
            <Send/>
              </div>   
              <div
                
                className="block text-center font-lexend font-semibold px-6 py-3 rounded-2xl text-2xl text-black"
              >
              Let's get connected and take things further!
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
