import React, { useEffect, useState, useRef } from 'react';
import Carousel from './3dcarousel';
import Background from "./0_BackgroundMD.png";
import Mobile from './Mobile BG  Image.png';
import Bar from './navbar';
import { IoArrowDownSharp } from "react-icons/io5";
import { FaVolleyballBall } from "react-icons/fa";
const Home = () => {
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const heroRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (heroRef.current) {
        const react = heroRef.current.getBoundingClientRect();
      }
    };

    if (scrollEnabled) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [scrollEnabled]);

  useEffect(() => {
    document.body.style.overflow = scrollEnabled ? 'auto' : 'hidden';
  }, [scrollEnabled]);

  const handleButtonClick = () => {
    setScrollEnabled(true);
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div id='homescreen' ref={heroRef} className="relative w-full h-screen overflow-hidden">
      <div
        className="absolute inset-0 w-full bg-cover bg-center"
        style={{ backgroundImage: `url(${Background})` }}
      ></div>
      <div
        className="absolute md:hidden inset-0 clip-square"
        style={{ backgroundImage: `url(${Mobile})` }}
      ></div>

      <Bar />

      <Carousel />
<div>


      <button 
        onClick={handleButtonClick}
        className="md:absolute md:bottom-9 md:right-4 absolute bottom-16 hover:bg-orange-500  right-4 animate-bounce border-8 border-white/50  bg-customOrange py-1 px-1  md:py-2 md:px-2 rounded-full"
        >
        <i className="text-3xl font-light text-white">
          <IoArrowDownSharp />
        </i>
      </button>
      {/* <div className='border-2 border-white py-5 px-1  rounded-2xl absolute bottom-9 right-20'>
        <div className='custom-bounce'>
        <i className=' text-sm'>
          <FaVolleyballBall/>
        </i>
        </div>
      </div> */}
        </div>
    </div>
  );
};

export default Home;
