import React from "react";
import Boxnavbar from "./component/Nav/boxnavbar";
import Home from "./component/content/pages/Home";
import Contact from "./component/content/Findus/Contact";
import Interact from "./component/content/skid/Interact";
import Commitment from "./component/content/skid/commitment";
import About from "./component/content/Experties/About";
import Footer from "./component/content/Findus/footer";
import Bintogrid from "./component/content/Experties/Bintogrid";
import "@fontsource/lexend/500.css";
import Scroll from "./component/content/Findus/scroll";
const App = () => {
  return (
    <div className="bg-black font-[Lexend] ">
      <div className="flex flex-col">
        <div className="flex-1  ">
          <section id="Home" className="h-screen overflow-hidden">
            <h1>
              <Home />
            </h1>
          </section>
          <div className="flex-none  xl:sticky xl:top-0 xl:z-30 sticky top-0 z-30  ">
            <Boxnavbar />
          </div>
          <div className="">
            <section id="About">
              <h1>
                <About />
              </h1>
            </section>
            <Bintogrid/>
            <section id="Interact">
              <h1>
                <Interact />
              </h1>
            </section>
            <Commitment />
            <section id="Contact  " className="bg-white  "  >
              <h1 className="">
                <Contact />
                <Footer/>
              </h1>
            </section>
                {/* <Scroll/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;


// import React from "react";

// const OppositeScrollSlides = () => {
//   return (
//     <div className="relative">
//       {/* Fourth Slide */}
//       <div className="sticky top-0 h-screen  rounded-b-[100px] flex flex-col items-center justify-center bg-gradient-to-b from-blue-200 to-indigo-100 text-black">
//         <h2 className="text-4xl font-bold">The Fourth Slide</h2>
//         <p className="mt-2">Scroll Down for the next slide</p>
//       </div>
      
//       {/* Third Slide */}
//       <div className="sticky  top-0 h-screen rounded-b-[100px] flex flex-col items-center justify-center bg-gradient-to-b from-purple-800 to-pink-800 text-white">
//         <h2 className="text-4xl font-bold">The Third Slide</h2>
//         <p className="mt-2">Scroll Down for the next slide</p>
//       </div>
      
//       {/* Second Slide */}
//       <div className="sticky top-0 rounded-b-[100px] h-screen flex flex-col items-center justify-center bg-gradient-to-b from-indigo-800 to-purple-800 text-white">
//         <h2 className="text-4xl font-bold">The Second Slide</h2>
//         <p className="mt-2">Scroll Down for the next slide</p>
//       </div>
      
//       {/* First Slide */}
//       <div className="sticky top-0 rounded-b-[100px] h-screen flex flex-col items-center justify-center bg-gradient-to-b from-green-200 to-blue-200">
//         <h2 className="text-4xl font-bold">The First Slide</h2>
//         <p className="mt-2">Scroll Down</p>
//       </div>
//     </div>
//   );
// };

// export default OppositeScrollSlides;
